import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Typography, Dropdown, Divider } from 'antd';
import type { MenuProps } from 'antd';

// icons
import { DownArrowLogo, LogoutLogo, SettingsLogo } from 'assets/svg';

// context
import { AuthContext } from 'context';

const { Title, Text } = Typography;

const Navbar = () => {
    const navigate = useNavigate();
    const {
        state: { user },
    } = useContext(AuthContext);

    const role = user?.role === 'admin' ? 'Admin' : user?.role === 'user' ? 'Company Owner' : 'Company Member';

    const items: MenuProps['items'] = [
        {
            key: '1',
            disabled: true,
            label: (
                <div className="flex flex-col">
                    <div className="flex flex-col">
                        <Text className="text-sm font-semibold">{user?.name}</Text>
                        <Text className="text-xs text-gray-500">{role}</Text>
                    </div>
                    <Divider className="my-1" />
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className="flex items-center">
                    <SettingsLogo className="mr-4" />
                    Account Settings
                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div
                    onClick={() => {
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }}
                    className="flex items-center"
                >
                    <LogoutLogo className="mr-4" />
                    Log Out
                </div>
            ),
        },
    ];

    const nameArray = user?.name?.split(' ');
    const firstName = nameArray?.length > 1 ? nameArray[0] : '-';
    const lasName = nameArray?.length > 1 ? nameArray[1] : '-';

    return (
        <nav className="flex items-center justify-between flex-wrap p-5">
            <div className="flex flex-1 items-center">
                <Title className="m-0" level={5}>
                    ConversationIQ
                </Title>
            </div>
            <div className="flex justify-center items-center">
                <Dropdown
                    menu={{ items }}
                    className="cursor-pointer"
                    placement="bottom"
                    arrow={{ pointAtCenter: true }}
                >
                    <div className="flex">
                        <div className="flex justify-center items-center mr-4">
                            {user?.photo_url ? (
                                <img src={user.photo_url} alt="avatar" className="h-10 w-10 mr-3 rounded-full" />
                            ) : (
                                <div className="flex justify-center items-center text-white font-bold bg-primary h-10 w-10 mr-3 rounded-full">
                                    {firstName[0]}
                                    {lasName[0]}
                                </div>
                            )}

                            <div className="flex flex-col">
                                <Text className="text-sm font-semibold">{user?.name}</Text>
                                <Text className="text-xs text-gray-500">{role}</Text>
                            </div>
                        </div>
                        <a href="#" className="flex justify-center items-center h-10 w-10">
                            <DownArrowLogo />
                        </a>
                    </div>
                </Dropdown>
            </div>
        </nav>
    );
};

export default Navbar;
