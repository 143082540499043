import React, { useState, useEffect } from 'react';
import { Table, Input, Select } from 'antd';
import type { PaginationProps } from 'antd';

import {
    HorizonPositive,
    HorizonAlmostPositive,
    HorizonNegative,
    HorizonAlmostNegative,
    HorizonMiddle,
    TableAzSortingIcon,
    Table91SortingIcon,
    TableSortingIcon,
    TableFilterIcon,
    InputSearchIcon,
    EyeIcon,
    CalendarIcon,
    AddCircleIcon,
    RowFilterIcon,
    TablePaginationNextIcon,
    TablePaginationPreviousIcon,
    UserMultiple,
} from 'assets/metropolis';

const AnswerTwo = ({ data }: { data: any }) => {
    const [tableHeight, setTableHeight] = useState(450);

    const paginationItemRender: PaginationProps['itemRender'] = (number, type, originalElement) => {
        if (type === 'prev') {
            return (
                <a>
                    <TablePaginationPreviousIcon style={{ marginRight: 12 }} />
                </a>
            );
        }
        if (type === 'next') {
            return (
                <a>
                    <TablePaginationNextIcon style={{ marginLeft: 12, marginRight: 12 }} />
                </a>
            );
        }
        return originalElement;
    };

    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'customer',
            key: 'customer',
            sorter: true,
            sortIcon: () => <TableAzSortingIcon />,
            showSorterTooltip: false,
            render: (customer: any) => (
                <div>
                    {customer.first_name} {customer.last_name}
                </div>
            ),
        },
        {
            title: 'Last Contact Date',
            dataIndex: 'follow_up_at',
            key: 'follow_up_at',
            sorter: true,
            sortIcon: () => <Table91SortingIcon />,
            showSorterTooltip: false,
            render: (item: any) => new Date(item).toLocaleDateString(),
        },
        {
            title: 'Channel',
            dataIndex: 'campaign',
            key: 'campaign',
            filters: [],
            filterIcon: <TableFilterIcon />,
            render: (campaign: any) => <div className="capitalize">{campaign.channel.toLowerCase()}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            sortIcon: () => <TableSortingIcon />,
            showSorterTooltip: false,
            render: (item: any) => <div className="capitalize">{item.toLowerCase()}</div>,
        },
        {
            title: 'Temperature',
            dataIndex: 'temperature',
            key: 'temperature',
            sorter: true,
            sortIcon: () => <TableSortingIcon />,
            showSorterTooltip: false,
            render: (temperature: any) => (
                <div className="text-[#B2CFD7] text-[10px] flex items-center">
                    <div className="mr-3">0</div>
                    <div className="w-[114px] h-[10px] bg-[#E6EFF2] rounded-[100px] relative">
                        {0 <= temperature && temperature <= 20 ? (
                            <HorizonNegative className="absolute bottom-0" />
                        ) : 20 < temperature && temperature <= 40 ? (
                            <HorizonAlmostNegative className="absolute bottom-0" />
                        ) : 40 < temperature && temperature <= 60 ? (
                            <HorizonMiddle />
                        ) : 60 < temperature && temperature <= 80 ? (
                            <HorizonAlmostPositive className="absolute bottom-0" />
                        ) : 80 < temperature && temperature <= 100 ? (
                            <HorizonPositive className="absolute bottom-0" />
                        ) : (
                            <HorizonNegative className="absolute bottom-0" />
                        )}
                    </div>
                    <div className="ml-3">100</div>
                </div>
            ),
        },
    ];

    // make table height dynamic to make it always scroll on a good height
    useEffect(() => {
        const updateTableHeight = () => {
            const height = window.innerHeight - 500;
            setTableHeight(height);
        };

        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);

    return (
        <div className="flex flex-col flex-1 w-full">
            <div className="mt-9 animate-slideInFromTop">
                <div className="text-[#044A5E] text-[44px] font-bold mb-3">Follow ups for this week</div>
                <div className="text-[#B2CFD7] text-[25px] font-normal">
                    Here are the leads I decided to follow up this week based on last actions
                </div>
            </div>

            <div className="flex mt-9 animate-slideInFromBottom">
                <Table
                    size="small"
                    scroll={{ y: tableHeight }}
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        itemRender: paginationItemRender,
                        showSizeChanger: true,
                        showTotal: (total) => (
                            <div className="flex items-center mr-6">
                                <UserMultiple />
                                <span className="ml-2 text-[#044A5E] font-bold">{total}</span>
                            </div>
                        ),
                    }}
                />

                {/* Tool Panel */}
                <div className="common-card-border-shadow ml-9 h-fit min-w-[200px] mt-3">
                    <div className="p-3">Tool Panel</div>
                    <div className="divider-horizon-e6eff2" />
                    <div className="p-3 text-xs text-[#044A5E]">
                        <Input placeholder="Search on table" prefix={<InputSearchIcon style={{ marginRight: 6 }} />} />
                        <div className="flex w-full justify-between items-center mt-3">
                            <div className="flex items-center">
                                <EyeIcon style={{ marginRight: 8 }} />
                                Show
                            </div>
                            <Select
                                size="small"
                                defaultValue="bookmarked"
                                style={{ width: 100 }}
                                className="metro-mini-select"
                                onChange={() => {}}
                                options={[{ value: 'bookmarked', label: 'Bookmarked' }]}
                            />
                        </div>
                    </div>
                    <div className="divider-horizon-e6eff2" />
                    <div className="flex justify-between items-center p-3 text-xs text-[#044A5E]">
                        <div className="flex items-center">
                            <CalendarIcon style={{ marginRight: 8 }} />
                            Date
                        </div>
                        <Select
                            size="small"
                            defaultValue="thisweek"
                            style={{ width: 100 }}
                            className="metro-mini-select"
                            onChange={() => {}}
                            options={[{ value: 'thisweek', label: 'This Week' }]}
                        />
                    </div>
                    <div className="divider-horizon-e6eff2" />
                    <div className="flex justify-between items-center p-3 text-xs text-[#044A5E]">
                        <div className="flex items-center">
                            <CalendarIcon style={{ marginRight: 8 }} />
                            Columns
                        </div>
                        <AddCircleIcon />
                    </div>
                    <div className="divider-horizon-e6eff2" />
                    <div className="flex justify-between items-center p-3 text-xs text-[#044A5E]">
                        <div className="flex items-center">
                            <RowFilterIcon style={{ marginRight: 8 }} />
                            Filter
                        </div>
                        <AddCircleIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnswerTwo;
