import React from 'react';

import { FakeProfileImage, InfoOutline, NotificationNoe } from 'assets/metropolis';

const Header = () => {
    return (
        <div className="flex justify-between items-center mt-10">
            <div />
            <div className="flex items-center px-5 py-2 shadow-notification-rectangle rounded-2xl">
                <NotificationNoe className="mr-5" />
                <InfoOutline className="mr-5" />
                <FakeProfileImage />
            </div>
        </div>
    );
};

export default Header;
