import { SIGN_IN_USER_SUCCESS, SIGN_IN_ERROR, IS_LOADING, SET_USER, REMOVE_USER } from 'context/auth/keys';

export const initialState = {
    signInError: '',
    user: {
        id: '',
        name: '',
        role: '',
        photo_url: '',
    },
    active_account: {
        id: '',
        name: '',
        timezone: '',
        logo_url: '',
    },
    memberships: [],
    isLoading: false,
    authenticated: false,
};

const reducer = (state = initialState, { type, payload }: { type: string; payload: any }) => {
    switch (type) {
        case SIGN_IN_USER_SUCCESS: {
            const { active_account, memberships, ...user } = payload;
            return {
                ...state,
                isLoading: false,
                signInError: '',
                authenticated: true,
                user,
                active_account,
                memberships,
            };
        }
        case SIGN_IN_ERROR:
            return { ...state, isLoading: false, signInError: payload, authenticated: false };
        case IS_LOADING:
            return { ...state, isLoading: payload };
        case SET_USER: {
            const { active_account, memberships, ...user } = payload;
            return { ...state, user, active_account, memberships, authenticated: true };
        }
        case REMOVE_USER:
            return { ...state, user: {}, active_account: {}, memberships: [], authenticated: false };
        default:
            return state;
    }
};

export default reducer;
