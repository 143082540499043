import React, { useState } from 'react';

// components
import { Breadcrumb, Row, Col, Card, Select } from 'antd';
import Spinner from 'components/spinner';
import InsightCard from './insight-card';

interface IInsight {
    conversations_by_status: IConversationsByStatus;
    conversations_by_reason: IConversationsByReason;
    conversations: {
        total: 100;
        rate: 0;
    };
    bookings: {
        total: 100;
        rate: 0;
    };
}

interface IConversationsByStatus {
    in_conversation: 12;
    error: 11;
    inactive: 13;
    completed: 20;
    rejected: 11;
    awaiting_agent: 12;
    closed: 21;
}

interface IConversationsByReason {
    other: 13;
    booking_request: 19;
    status_check: 17;
    booking_reschedule: 20;
    booking_cancel: 14;
    report_complaint: 17;
}

interface IConversationReasonItem {
    reason: string;
    count: number;
}

interface IConversationCountItem {
    status: string;
    count: number;
}

export default function Home() {
    const [isLoading] = useState(false);
    const [insights] = useState<IInsight>();
    const [totalConversationReason] = useState(0);
    const [insightPeriod, setInsightPeriod] = useState('one_month');
    const [conversationReasons] = useState<IConversationReasonItem[]>([]);
    const [conversationCounts] = useState<IConversationCountItem[]>([]);

    return (
        <div className="flex flex-col flex-1">
            <div className="flex justify-between items-center mb-5">
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                    ]}
                />
                <Select
                    value={insightPeriod}
                    style={{ width: 120 }}
                    onChange={(value) => setInsightPeriod(value)}
                    options={[
                        { value: 'one_week', label: '1 Week' },
                        { value: 'one_month', label: '1 Month' },
                        { value: 'three_months', label: '3 Months' },
                        { value: 'six_months', label: '6 Months' },
                        { value: 'one_year', label: '1 Year' },
                    ]}
                />
            </div>
            <Row gutter={24}>
                <Col span={12}>
                    <Card styles={{ body: { height: '100%', padding: 0 } }} className="h-full p-6">
                        <div className="flex justify-between items-center mb-3">
                            <div className="text-primary-900 text-lg font-bold">Conversation Status</div>
                            <div className="text-primary-600 text-sm font-semibold">
                                Total: {insights?.conversations?.total}
                            </div>
                        </div>
                        {conversationCounts?.map((item) => {
                            return (
                                <div key={item.status} className="flex mt-3 items-center">
                                    <div className="ml-3 text-primary-600 text-sm">{item.count}</div>
                                </div>
                            );
                        })}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card styles={{ body: { height: '100%', padding: 0 } }} className="h-full p-6">
                        <div className="flex justify-between items-center mb-6">
                            <div className="text-primary-900 text-lg font-bold">Conversation Reasons</div>
                            <div className="text-primary-600 text-sm font-semibold">
                                Total: {totalConversationReason}
                            </div>
                        </div>
                        <Row gutter={24}>
                            {conversationReasons?.map((item, index) => {
                                return (
                                    <Col key={item.reason} span={8}>
                                        <div
                                            className={`${
                                                index < 3 && 'mb-6'
                                            } border border-solid border-greyscale-200 rounded-lg p-6 pb-8`}
                                        >
                                            <div className="text-primary-900 text-lg font-medium mt-2">
                                                {item.count}
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 27 }}>
                <Col span={8}>
                    <InsightCard
                        title="Total Conversation"
                        total={insights?.conversations?.total as number}
                        rate={insights?.conversations?.rate as number}
                    />
                </Col>
                <Col span={8}>
                    <InsightCard
                        title="Total Bookings"
                        total={insights?.bookings?.total as number}
                        rate={insights?.bookings?.rate as number}
                    />
                </Col>
                <Col span={8}>
                    <InsightCard title="Av. Time Spent on Conversation" total={54 as number} rate={10.0 as number} />
                </Col>
            </Row>
            <Spinner visible={isLoading} />
        </div>
    );
}
