import React from 'react';
import { Link } from 'react-router-dom';

import { AnswerThreeChart, Target, ChartAverage, UserGroup, Vector } from 'assets/metropolis';

const AnswerThree = () => {
    return (
        <div className="flex flex-1 flex-col justify-center">
            <div className="absolute top-[9%] left-[40%]">
                <Vector />
            </div>
            <div className="text-[#044A5E] text-[44px] font-bold mb-3 animate-slideInFromLeft">Optimizing board for the next 3 weeks</div>
            <div className="flex justify-between items-center">
                <div className="flex justify-between">
                    <div className="z-10 flex flex-col flex-1 mr-12 animate-slideInFromLeft">
                        <div className="text-[#B2CFD7] text-[25px] font-normal mb-6">
                            I started reaching out to Recurring Service customers for the next three weeks and started
                            filling up the board.
                        </div>
                        <div className="text-[#B2CFD7] text-[25px] font-normal mb-6">
                            Please check back in 24 hours to see the progress from{' '}
                            <Link className="text-[#044A5E]" to="#">
                                here.
                            </Link>
                        </div>
                        <div className="z-10 bg-white flex rounded-[14px] border border-solid border-[#E6EFF2]">
                            <div className="flex-1 border-solid border-0 border-r border-[#E6EFF2] px-6 py-8">
                                <div className="flex items-center">
                                    <div className="mr-6">
                                        <ChartAverage />
                                    </div>
                                    <div>
                                        <div className="text-[#044A5E] text-base font-normal mb-3">
                                            Average capacity used
                                        </div>
                                        <div className="text-[#02232C] text-4xl font-normal">%40</div>
                                    </div>
                                </div>
                                <div className="flex flex-1 h-px w-full bg-[#E6EFF2] my-4" />
                                <div className="flex items-center">
                                    <div className="mr-6">
                                        <Target />
                                    </div>
                                    <div>
                                        <div className="text-[#044A5E] text-base font-normal mb-3">Goal</div>
                                        <div className="text-[#02232C] text-4xl font-normal">%80</div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 px-6 py-8 flex items-center">
                                <div>
                                    <div className="mb-6">
                                        <UserGroup />
                                    </div>
                                    <div className="text-[#044A5E] text-base font-normal mb-3">
                                        Number of people contacted to achieve the goal
                                    </div>
                                    <div className="text-[#02232C] text-4xl font-normal">154</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="z-10 mt-[-24px] animate-slideInFromRight">
                        <AnswerThreeChart />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnswerThree;
