import React, { useState, useEffect } from 'react';

import { MiddleLinear, NegativeLinear, PositiveLinear, AlmostPositive, FakeUser1, Vector } from 'assets/metropolis';
import { Dropdown } from 'antd';

const AnswerOne = ({ data }: { data: any }) => {
    const [activeCard, setActiveCard] = useState('');
    const [leadCounts, setLeadCounts] = useState([
        { label: 'Awareness', width: 'w-[696px]', leadCount: '0', leadList: [] },
        { label: 'Acquisition', width: 'w-[684px]', leadCount: '0', leadList: [] },
        { label: 'Activation', width: 'w-[672px]', leadCount: '0', leadList: [] },
        { label: 'Retention', width: 'w-[660px]', leadCount: '0', leadList: [] },
        { label: 'Revenue', width: 'w-[648px]', leadCount: '0', leadList: [] },
        { label: 'Referral', width: 'w-[636px]', leadCount: '0', leadList: [] },
    ]);

    useEffect(() => {
        if (data) {
            const updatedLeadCounts: any = [...leadCounts];

            data.forEach((item: any) => {
                const { status, customer, follow_up_at } = item;
                const leadIndex = updatedLeadCounts.findIndex(
                    (lead: any) => lead.label.toLowerCase() === status.toLowerCase(),
                );

                if (leadIndex !== -1) {
                    updatedLeadCounts[leadIndex].leadCount++;
                    updatedLeadCounts[leadIndex].leadList.push({
                        key: item.id,
                        name: customer.first_name,
                        lastName: customer.last_name,
                        lastContactDate: new Date(follow_up_at).toLocaleDateString(),
                        channel: item.campaign.channel,
                        status: getStatusComponent(item.temperature),
                        image: getFakeUserComponent(),
                    });
                }
            });

            setLeadCounts(updatedLeadCounts);
        }
    }, [data.length]);

    const getStatusComponent = (temperature: number) => {
        if (0 <= temperature && temperature <= 25) {
            return <NegativeLinear className="absolute bottom-0" />;
        } else if (25 < temperature && temperature <= 50) {
            return <MiddleLinear className="absolute bottom-0" />;
        } else if (50 < temperature && temperature <= 75) {
            return <AlmostPositive className="absolute bottom-0" />;
        } else if (75 < temperature && temperature <= 100) {
            return <PositiveLinear className="absolute bottom-0" />;
        } else {
            return <NegativeLinear className="absolute bottom-0" />;
        }
    };

    const getFakeUserComponent = () => {
        // Implement logic to return different FakeUser components
        return <FakeUser1 />;
    };

    return (
        <div onClick={() => setActiveCard('')} className="flex flex-1 w-full justify-between items-center">
            <div className="absolute top-[9%] left-0">
                <Vector />
            </div>
            <div className="mr-16 animate-slideInFromLeft">
                <div className="text-[#044A5E] text-[44px] font-bold mb-3">Here are the results</div>
                <div className="text-[#B2CFD7] text-[25px] font-normal">
                    Your leads in the last 30 days based on their latest interactions
                </div>
            </div>

            <div className="flex flex-col items-end animate-slideInFromRight">
                <div className="text-[20px] text-[#05637D]">Lead Counts</div>
                {leadCounts.map((item) => (
                    // LEAD COUNT CARD
                    <div
                        key={item.label}
                        onClick={(event) => {
                            event.stopPropagation();
                            setActiveCard(item.label);
                        }}
                        className={`${activeCard && activeCard !== item.label ? 'blur-sm' : ''} ${
                            item.width
                        } common-card-border-shadow cursor-pointer flex items-center justify-between px-6 mt-3 h-24`}
                    >
                        <div className="text-[32px] text-[#02232C]">{item.label}</div>
                        <Dropdown
                            open={item.label === activeCard}
                            trigger={['click']}
                            placement="bottomRight"
                            dropdownRender={() => {
                                if (item.leadList.length) {
                                    return (
                                        <div className="p-3 common-card-border-shadow">
                                            {/* Dropdown Header */}
                                            <div className="flex justify-between items-center">
                                                <div className="text-[#02232C] ml-2">{item.label}Lead List</div>
                                                <div className="underline text-[#05637D] text-xs">Show more</div>
                                            </div>

                                            {/* Dropdown Items */}
                                            <div className="max-h-[40vh] overflow-auto w-full">
                                                {item.leadList.map((element: any) => (
                                                    <div
                                                        className="common-card-border-shadow mt-3 flex items-center justify-between w-[300px] p-3"
                                                        key={element?.key}
                                                    >
                                                        <div className="flex">
                                                            <div className="mr-3">
                                                                {element?.image ? (
                                                                    element?.image
                                                                ) : (
                                                                    <div className="rounded-full w-[26px] h-[26px] bg-[#E6EFF2] text-xs text-[#05637D] font-bold flex justify-center items-center">
                                                                        {element?.name[0]} {element?.lastName[0]}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                <div className="text-[#02232C] mb-3">
                                                                    {element?.name} {element?.lastName}
                                                                </div>
                                                                <div className="text-[#05637D] text-[10px] mb-3">
                                                                    Last Contact Date: {element?.lastContactDate}
                                                                </div>
                                                                <div className="text-[#05637D] text-[10px]">
                                                                    Channel: {element?.channel}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="text-[#B2CFD7] text-[10px] flex flex-col items-center justify-center">
                                                            <div>100</div>
                                                            <div className="w-[10px] h-[65px] bg-[#E6EFF2] rounded-[100px] relative">
                                                                {element?.status}
                                                            </div>
                                                            <div>0</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return <div />;
                                }
                            }}
                        >
                            <div className="text-xl text-[#05637D] underline">{item.leadCount}</div>
                        </Dropdown>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AnswerOne;
