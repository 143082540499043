import { jwtDecode } from 'jwt-decode';
import { axiosClient } from 'service';
import notify from 'utils/notification';

interface DecodedJwt {
    exp: number;
}

const isValidToken = (accessToken: string | null): boolean => {
    if (!accessToken) {
        return false;
    }

    const decoded: DecodedJwt = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    return decoded?.exp > currentTime;
};

const handleTokenExpired = (exp: number): void => {
    let expiredTimer: NodeJS.Timeout | null = null;

    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;
    if (timeLeft > 25920000) {
        return;
    }

    if (expiredTimer) {
        clearTimeout(expiredTimer);
    }

    expiredTimer = setTimeout(() => {
        notify({ type: 'warning', message: 'Token expired.' });

        localStorage.removeItem('accessToken');

        window.location.href = '/listening';
    }, timeLeft);
};

const setSession = (accessToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        const decoded: DecodedJwt = jwtDecode(accessToken) as { exp: number };
        handleTokenExpired(decoded.exp);
    } else {
        localStorage.removeItem('accessToken');
        delete axiosClient.defaults.headers.common.Authorization;
    }
};

export { isValidToken, setSession };
