import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// components
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';

// helpers
import { AuthContext } from 'context';
import { Role } from 'utils/constants';

// icons
import bucklogo from 'assets/buck-logo.png';
import {
    BuckLogo,
    HomeLogo,
    ConversationsLogo,
    TeamsLogo,
    SettingsLogo,
    RightArrowLogo,
    LeftArrowLogo,
    CustomerLogo,
    ServicesLogo,
    PhoneLogo,
} from 'assets/svg';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    onClick?: (e: React.MouseEvent<HTMLElement>) => void,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        onClick,
    } as MenuItem;
}

const Sidebar: React.FC = () => {
    const {
        state: { user, memberships },
    } = useContext(AuthContext);

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname);

    const items: MenuItem[] = [
        getItem('Home', '/', <HomeLogo />, () => navigate('/')),
        getItem('Conversations', '/conversations', <ConversationsLogo />, () => navigate('/conversations')),
        getItem('Bookings', '/appointments', <ServicesLogo />, () => navigate('/appointments')),
        getItem('Complaints', '/complaints', <ServicesLogo />, () => navigate('/complaints')),
        getItem('Customers', '/customers', <CustomerLogo />, () => navigate('/customers')),
        memberships?.[0]?.role === Role.OWNER || user.role === Role.ADMIN
            ? getItem('Integrations', '/integrations', <PhoneLogo />, () => navigate('/integrations'))
            : null,
        memberships?.[0]?.role === Role.OWNER || user.role === Role.ADMIN
            ? getItem('Users', '/users', <TeamsLogo />, () => navigate('/users'))
            : null,
        user?.role === Role.ADMIN
            ? getItem('Accounts', '/accounts', <SettingsLogo />, () => navigate('/accounts'))
            : null,
    ];

    const handleClick = (e: any) => {
        setCurrent(e.key);
    };

    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                setCurrent(location.pathname);
            }
        }
    }, [location, current]);

    return (
        <div className="flex flex-col">
            <div className="flex h-3rem w-full items-center justify-center py-5">
                {collapsed ? <img src={bucklogo} alt="buck" className="h-8" /> : <BuckLogo className="h-8 w-min" />}
            </div>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <Menu
                    onClick={handleClick}
                    selectedKeys={[current]}
                    className="h-[calc(100vh_-_6rem)]"
                    mode="inline"
                    items={items}
                />
            </Sider>
            <div
                className="flex h-3rem w-full items-center justify-center"
                onClick={() => setCollapsed((prev) => !prev)}
            >
                {collapsed ? <RightArrowLogo /> : <LeftArrowLogo />}
            </div>
        </div>
    );
};

export default Sidebar;
