import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';

import Header from 'components/header';
import Lottie from 'react-lottie';
import * as animationData from 'assets/metropolis/For_Animations/Circle1.json';
import * as microphoneHover from 'assets/metropolis/For_Animations/mic_hover.json';
// import * as animationData2 from 'assets/metropolis/For_Animations/Circle2.json';
import AnswerOne from './answers/answer-one';
import AnswerTwo from './answers/answer-two';
import AnswerThree from './answers/answer-three';
import { RegenerateResponseButton, MicrophoneButton } from 'assets/metropolis';
import AnimationGradientImage from 'assets/metropolis/For_Animations/Gradients/animationPng.png';

import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

const Listening: React.FC = () => {
    const [inputValue, setInputValue] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoverForMic, setHoverForMic] = useState(false);
    const [answerType, setAnswerType] = useState('');
    const [answerData, setAnswerData] = useState<any>(null);

    const recognitionRef = useRef<any>(null);

    useEffect(() => {
        const SpeechRecognition = (window as any).webkitSpeechRecognition;
        if (SpeechRecognition) {
            const recognition = new SpeechRecognition();
            recognition.lang = 'en-US';
            recognition.interimResults = false;
            recognition.maxAlternatives = 1;

            recognition.onstart = () => {
                setIsListening(true);
            };

            recognition.onend = () => {
                setIsListening(false);
            };

            recognition.onresult = (event: any) => {
                const transcript = event.results[0][0].transcript;

                if (transcript.includes('reach')) {
                    setAnswerType('3');
                } else {
                    sendApiRequestToAi(transcript);
                }
                setInputValue(transcript);
            };

            recognitionRef.current = recognition;
        } else {
            console.error('Web Speech API is not supported in this browser.');
        }
    }, []);

    const handleMicrophoneClick = () => {
        setInputValue('');
        setAnswerType('');
        if (recognitionRef.current && !isListening) {
            recognitionRef.current.start();
        }
    };

    const sendApiRequestToAi = async (spokenText: string) => {
        setIsLoading(true);
        try {
            const res = await axiosClient.post(apiURL.resolve, {
                prompt: spokenText,
            });

            if (res.data.component_type) {
                getRelevantDataFromBE({ endPoint: res.data.endpoint, queries: res.data.inputs });
                setAnswerType(res.data.component_type);
            } else {
                notify({ type: 'error', message: res.data });
                setIsLoading(false);
                setAnswerData(null);
                setAnswerType('');
            }
        } catch (error) {
            console.error('error fetching customers', error);
            setIsLoading(false);
        }
    };

    const getRelevantDataFromBE = async ({ endPoint, queries }: { endPoint: string; queries: any }) => {
        try {
            const res = await axiosClient.get(endPoint, {
                params: queries,
            });

            setAnswerData(res.data);
        } catch (error) {
            console.error('error fetching customers', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex h-screen flex-col px-32 overflow-hidden">
            <Header />

            {/* main-part with input and animation */}
            <div className="flex flex-1 w-full flex-col mb-10 justify-center items-center">
                {/* start listening */}
                {(isListening || isLoading) && (
                    <div className="relative flex-1 flex items-center">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice',
                                },
                            }}
                            height={350}
                            width={350}
                        />

                        <div className="absolute top-[-20%] right-[-120%] animate-rotate-gradient">
                            <img src={AnimationGradientImage} alt="buck" />
                        </div>
                        {/* <div className="absolute top-[30%] left-[7%] z-[-1]">
                            <CircleAnimation />
                        </div> */}
                        {isListening ? (
                            <div className="text-[#02232C] text-2xl absolute bottom-[25%] left-[32%]">
                                I’m listening
                            </div>
                        ) : isLoading ? (
                            <div className="text-[#02232C] text-2xl absolute bottom-[25%] left-[32%]">
                                I’m preparing
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}
                {/* end listening */}

                {!isListening && !isLoading && answerData && answerType === 'leads_list_component' && (
                    <AnswerOne data={answerData} />
                )}
                {!isListening && !isLoading && answerData && answerType === 'follow_up_list_component' && (
                    <AnswerTwo data={answerData} />
                )}
                {!isListening && !isLoading && answerType === '3' && <AnswerThree />}

                <Input
                    className={`h-[108px] rounded-[14px] shadow-listening-input border-0 px-6 text-xl ${
                        (isListening || isLoading) && !answerType && !answerData ? 'animate-slidelyGoDown' : ''
                    }`}
                    onPressEnter={() => {
                        if (inputValue.includes('reach')) {
                            setAnswerType('3');
                        } else {
                            sendApiRequestToAi(inputValue);
                        }
                    }}
                    prefix={<RegenerateResponseButton className="cursor-pointer mr-6" />}
                    suffix={
                        <div
                            className="cursor-pointer h-[44px] w-[44px]"
                            onMouseOver={() => setHoverForMic(true)}
                            onMouseOut={() => setHoverForMic(false)}
                            onClick={handleMicrophoneClick}
                        >
                            {hoverForMic ? (
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: microphoneHover,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice',
                                        },
                                    }}
                                    height={44}
                                    width={44}
                                />
                            ) : (
                                <MicrophoneButton className="cursor-pointer h-[44px] w-[44px]" />
                            )}
                        </div>
                    }
                    placeholder={'How can I help you Today?'}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
            </div>
        </div>
    );
};

export default Listening;
