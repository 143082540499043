const apiURL = {
    baseUrl: process.env.REACT_APP_API_URL,
    generalUrl: process.env.REACT_APP_API_GENERAL_URL,
    signIn: '/users/authenticate',
    acceptInvitation: '/users/accept_invitation',
    passwordReset: '/users/password_reset',
    getUser: '/users/',
    resolve: '/resolve',
    leads: '/leads',
};

export default apiURL;
