import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// auth routes
import Login from 'pages/login';
import SignUp from 'pages/signup';
import ResetPassword from 'pages/reset-password';
import ForgotPassword from 'pages/forgot-password';

// protected routes
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import ProtectedRoute from 'utils/protected-route';
import AcceptInvitation from 'pages/accept-invitation';
import Listening from 'pages/listening';
import Home from 'pages/home';

// context
import { AuthContext } from 'context';

// helpers
import { Role } from 'utils/constants';

function App() {
    const {
        state: { user },
    } = useContext(AuthContext);

    const isAdmin = user?.role === Role.ADMIN;
    console.log(isAdmin);
    const routes = [
        {
            path: '/',
            component: <Home />,
        },
        {
            path: '/reset-password',
            component: <ResetPassword />,
        },
        {
            path: '/forgot-password',
            component: <ForgotPassword />,
        },
    ];

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="accept-invitation" element={<AcceptInvitation />} />
                <Route path="listening" element={<Listening />} />
                <Route
                    path="*"
                    element={
                        <ProtectedRoute>
                            <div className="flex h-screen">
                                <Sidebar />
                                <div className="flex flex-1 flex-col overflow-hidden">
                                    <Navbar />
                                    <div className="flex flex-1 overflow-x-hidden overflow-y-auto bg-greyscale-50 p-5">
                                        <Routes>
                                            {routes.map(({ path, component }) => (
                                                <Route key={path} path={path} element={component} />
                                            ))}
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
